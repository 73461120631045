"use client";

import styles from "./sale-banner.module.scss";
import { usePathname, useRouter } from "next/navigation";
import { analytics } from "entity/analytics";
import { useEffect } from "react";
import { Snowfall } from "react-snowfall";
import Image from "next/image";
import * as images from "./assets";
interface ISaleBannerProps {
  borderRounded?: boolean;
  mobileBanner?: boolean;
}
export const SaleBanner = (props: ISaleBannerProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const bannerPath = pathname === "/men-home" ? "home" : pathname === "/women-home" ? "home" : pathname === "/my/wishlist" ? "favorites" : pathname.startsWith("/order") ? "order" : undefined;
  useEffect(() => {
    analytics.send(`new-year.banner.view-${bannerPath}`);
  }, []);
  return <>
      {props.mobileBanner ? <div className={styles.bannerMobile} data-adaptive={false} data-border-rounder={props.borderRounded} onClick={() => {
      router.push("/sale");
      analytics.send("new-year.banner.click");
    }}>
          <div className={styles.main}>
            <h1 className={styles.title}>Новогодняя&nbsp;распродажа</h1>
            <h1 className={styles.titleSlash}>
              Новогодняя
              <br />
              Распродажа
            </h1>
            <button className={styles.button}>
              Товары&nbsp;со&nbsp;скидками&nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M8.25 5.5L13.75 11L8.25 16.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </div>
          <div className={styles.ball}>
            <Image src={images.ball} alt="" />
          </div>
          <Snowfall snowflakeCount={25} speed={[0.2, 0.2]} wind={[0, 0]} />
          <div className={styles.ellipse} />
          <div className={styles.snowBackground} />
        </div> : <>
          <div className={styles.banner} onClick={() => {
        router.push("/sale");
        analytics.send(`new-year.banner.click-${bannerPath}`);
      }}>
            <div className={styles.empty} />
            <div className={styles.main}>
              <h1 className={styles.title}>Новогодняя&nbsp;распродажа</h1>
              <div className={styles.lines}>
                <div className={styles.blackLine}>быстрая доставка</div>
                <div className={styles.whiteLine}>большие скидки</div>
              </div>
            </div>
            <button className={styles.button}>
              Товары&nbsp;со&nbsp;скидками&nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M8.25 5.5L13.75 11L8.25 16.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
            <Snowfall snowflakeCount={30} speed={[0.2, 0.2]} wind={[0, 0]} />
            <div className={styles.snowBackground} />
            <div className={styles.ellipse} />
            <Image className={styles.ball} src={images.ballDesktop} alt="" />
          </div>
          <div className={styles.bannerMobile} data-border-rounder={props.borderRounded} onClick={() => {
        router.push("/sale");
        analytics.send("new-year.banner.click");
      }}>
            <div className={styles.main}>
              <h1 className={styles.title}>Новогодняя&nbsp;распродажа</h1>
              <h1 className={styles.titleSlash}>
                Новогодняя
                <br />
                Распродажа
              </h1>
              <button className={styles.button}>
                Товары&nbsp;со&nbsp;скидками&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M8.25 5.5L13.75 11L8.25 16.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </div>
            <div className={styles.ball}>
              <Image src={images.ball} alt="" />
            </div>
            <Snowfall snowflakeCount={25} speed={[0.2, 0.2]} wind={[0, 0]} />
            <div className={styles.ellipse} />
            <div className={styles.snowBackground} />
          </div>
        </>}
    </>;
};